import React from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import { View, Heading, Image, Button, Text, Collection, Card, Badge, Divider, Flex } from '@aws-amplify/ui-react'; // Import View from AWS Amplify UI
import landingImage from '../landing_bg_img.png'; // Adjust the path as necessary
import dashboardImage from '../dashboard_icon.png';
import shareImage from '../share_icon.png';
import recordImage from '../mic_icon.png';
import qualityImage from '../feedback_icon.png';
import secureImage from '../shield_icon.png';
import reinventImage from '../bulb_icon.png';

import { sendEvent } from 'react-ga4'; // Import sendEvent for tracking events

function LandingPage() {
    const navigate = useNavigate(); // Initialize navigate for navigation

    const handleSignUpClick = () => {
        navigate('/thanks-signup'); // Navigate to ThanksSignUp page
        // sendEvent({ category: 'Sign Up', action: 'Click' }); // Track the sign up button click
        ReactGA.send({ hitType: "event", eventCategory: "Sign Up", eventAction: "Sign Up Click" });
    };

    const howItWorksItems = [
        {
          title: 'Step 1',
          badges: ['Record'],
          img_src: recordImage,
          img_alt: "Record",
          description: "Submit a recording of your voice and get personalized feedback."
        },
        {
          title: 'Step 2',
          badges: ['Dashboard'],
          img_src: dashboardImage,
          img_alt: "Dashboard",
          description: "We give you a dashboard showing how real humans perceive your voice."
        },
        {
            title: 'Step 3',
            badges: ['Share'],
            img_src: shareImage,
            img_alt: "Share",
            description: "Share results with others, and monitor your growth over time."
        },
    ];

    const benefitsItems = [
        {
          title: 'Get Quality Feedback',
          img_src: qualityImage,
          img_alt: "quality",
          description: "All feedback is verified by real humans. Get feedback across demographics, vocal features and more."
        },
        {
          title: 'Secure and Anonymous',
          img_src: secureImage,
          img_alt: "secure",
          description: "Recordings are encrypted and stored securely. We don't share your data with anyone."
        },
        {
            title: 'Re-invent Your Voice',
            img_src: reinventImage,
            img_alt: "re-invent",
            description: "Track how your metrics change over time, A/B test various styles, test with specific audiences."
        },
    ];

    return (
        <View>
            <View position="relative">
                <Image
                    alt="Landing Page Image"
                    src={landingImage} // Use the imported image
                    objectFit="initial"
                    objectPosition="center" // Center the image
                    margin="0 auto" // Center the image horizontally
                    display="block" // Ensure the image is treated as a block element
                    width="100%" // Ensure the image takes full width
                    height="5%" // Adjusted height to make the image shorter
                />

                <Heading position="absolute" top="10%" left="10%" level={1} color="#eeaf37" style={{ fontSize: '5rem', fontWeight: 'bold' }}>
                    What's Your Voice Saying About Your Personal Brand?
                </Heading>

                <Text position="absolute" top="50%" left="10%" color="#ffffff" style={{ fontSize: '1.5rem', fontWeight: 'bold', backgroundColor: '#d32f9c', marginBottom: '1rem' }}>
                    RateMyVoice is a platform to design your personal voice.
                </Text>

                <Text position="absolute" top="56%" left="10%" color="#ffffff" style={{ fontSize: '1.5rem', fontWeight: 'bold', backgroundColor: '#d32f9c', marginBottom: '1rem' }}>
                    Voice can be a powerful tool to express your brand.
                </Text>

                <Text position="absolute" top="62%" left="10%" color="#ffffff" style={{ fontSize: '1.5rem', fontWeight: 'bold', backgroundColor: '#d32f9c', marginBottom: '1rem' }}>
                    We help you understand how your voice is perceived by others,
                </Text>

                <Text position="absolute" top="68%" left="10%" color="#ffffff" style={{ fontSize: '1.5rem', fontWeight: 'bold', backgroundColor: '#d32f9c', marginBottom: '1rem' }}>
                    You'll get personalized feedback with metrics like
                </Text>

                <Text position="absolute" top="74%" left="10%" color="#ffffff" style={{ fontSize: '1.5rem', fontWeight: 'bold', backgroundColor: '#d32f9c' }}>
                    clarity, confidence, age and more.
                </Text>

                <Button position="absolute" top="90%" left="10%" size="large" backgroundColor="#eeaf37" color="#000000" onClick={handleSignUpClick}>
                    Sign Up
                </Button>
            </View>

            <View backgroundColor="#f5f5f5" style={{ paddingTop: '3rem', paddingBottom: '3rem' }}>
                <Heading level={1} style={{ textAlign: 'center', color: 'black', marginBottom: '5rem', fontWeight: '400' }}>
                    How It Works
                </Heading>

                <Collection
                    items={howItWorksItems}
                    type="list"
                    direction="row"
                    gap="20px"
                    wrap="nowrap"
                    style={{ justifyContent: 'center' }} // Center the cards
                >
                    {(item, index) => (
                        <Card
                            key={index}
                            borderRadius="medium"
                            maxWidth="20rem"
                            variation="outlined"
                            style={{ textAlign: 'center' }}
                        >
                            <Heading padding="medium" level={3}>{item.title}</Heading>

                            <View padding="md" style={{ textAlign: 'center', marginBottom: '1rem' }}> {/* Added marginBottom for space after the view */}
                                <Flex direction="row" justifyContent="center" alignItems="center">
                                {item.badges.map((badge) => (
                                    <Badge
                                        key={badge}
                                        backgroundColor='#d32f9c'
                                    >
                                        {badge}
                                    </Badge>
                                ))}
                                </Flex>
                            </View>

                            <Image
                                src={item.img_src}
                                alt={item.img_alt}
                                style={{ width: '80%', height: 'auto' }}
                                backgroundColor="#ffffff"
                            />

                            <Divider padding="xs" />

                            <Text padding="medium">{item.description}</Text>

                        </Card>
                                )}
                </Collection>
            </View>

            <Divider color="grey" />

            <View backgroundColor="#f5f5f5" style={{ paddingTop: '3rem', paddingBottom: '3rem' }}>
                <Heading level={1} style={{ textAlign: 'center', color: 'black', marginBottom: '5rem', fontWeight: '400' }}>
                    Benefits
                </Heading>

                <Collection
                    items={benefitsItems}
                    type="list"
                    direction="row"
                    gap="20px"
                    wrap="nowrap"
                    style={{ justifyContent: 'center' }} // Center the cards
                >
                    {(item, index) => (
                        <Card
                            key={index}
                            borderRadius="medium"
                            maxWidth="20rem"
                            variation="outlined"
                            style={{ textAlign: 'center' }}
                        >
                            <Heading padding="medium" level={3}>{item.title}</Heading>

                            <Image
                                src={item.img_src}
                                alt={item.img_alt}
                                style={{ width: '80%', height: 'auto' }}
                                backgroundColor="#ffffff"
                            />

                            <Divider padding="xs" />

                            <Text padding="medium">{item.description}</Text>

                        </Card>
                                )}
                </Collection>
            </View>

            <Divider color="#d32f9c" />

            <View backgroundColor="#f5f5f5" style={{ paddingTop: '3rem', paddingBottom: '3rem' }} justifyContent="center" alignItems="center">
                <Heading level={1} style={{ textAlign: 'center', color: 'black', marginBottom: '5rem', fontWeight: '400' }}>
                    Know Your Voice
                </Heading>

                <Text style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold', backgroundColor: '#d32f9c', marginBottom: '5rem' }}>
                    Sign up for free to show your interest and request more features.
                </Text> 
                
                <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}> {/* Added View for centering */}
                    <Button 
                        size="large" 
                        backgroundColor="#eeaf37" 
                        color="#000000" 
                        onClick={handleSignUpClick}
                    >
                        I'm Interested
                    </Button>
                </View>
            </View>
        </View>
    )
}

export default LandingPage;
