import React, { useEffect } from 'react';

function Products({ onSelect, products, setProducts, prices, setPrices }) {
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/products`)
      .then(response => response.json())
      .then(data => setProducts(data))
      .catch(error => console.error('Error fetching products:', error));
  }, [setProducts]);

  useEffect(() => {
    const fetchPrices = async () => {
      const priceData = {};
      for (const product of products) {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/prices?product_id=${product.id}`);
          const data = await response.json();
          if (data && data.length > 0) {
            priceData[product.id] = {
              amount: data[0].unit_amount / 100,
              currency: data[0].currency.toUpperCase(),
              price_id: data[0].id
            };
          }
        } catch (error) {
          console.error(`Error fetching price for product ${product.id}:`, error);
        }
      }
      setPrices(priceData);
    };

    if (products.length > 0) {
      fetchPrices();
    }
  }, [products, setPrices]);

  const handleProductSelect = (product) => {
    onSelect({
      selectedProduct: product,
      prices: prices,
    });
  };

  return (
    <div className="product-selection">
      <h1 className="product-title">Choose Your Voice Test</h1>
      <div className="product-container">
        {products.map((product, index) => (
          <div key={index} className="product-card">
            {product.images && (
              <img src={product.images[0]} alt={product.name} className="product-image" />
            )}
            <h2>{product.name}</h2>
            <p className="product-description">{product.description}</p>
            {prices[product.id] ? (
              <p className="product-price">
                {prices[product.id].currency} {prices[product.id].amount.toFixed(2)}
              </p>
            ) : (
              <p className="product-price">Loading price...</p>
            )}
            <button onClick={() => handleProductSelect(product)}>Take Test</button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Products;