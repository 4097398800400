import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function CheckoutForm({ price, audioBlob, uploadedFile }) {
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [customerEmail, setCustomerEmail] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (error) {
      setError(error.message);
      setProcessing(false);
    } else {
      try {
        // First, upload the audio file
        const formData = new FormData();
        formData.append('file', audioBlob || uploadedFile);
        const uploadResponse = await fetch(`${process.env.REACT_APP_API_URL}/upload_audio`, {
          method: 'POST',
          body: formData,
        });

        if (!uploadResponse.ok) {
          throw new Error('Audio upload failed');
        }

        const uploadResult = await uploadResponse.json();
        console.log('Payment mehhod:' + JSON.stringify(paymentMethod, null, 4));
        // Then, process the payment
        const paymentResponse = await fetch(`${process.env.REACT_APP_API_URL}/process_payment`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            amount: (price * 100), // Amount in cents
            currency: 'cad',
            payment_method_id: 'tok_visa',
            customer_email: customerEmail, // Use the customerEmail state
          }),
        });

        if (!paymentResponse.ok) {
          throw new Error('Payment failed');
        }

        const paymentResult = await paymentResponse.json();
        console.log('Payment successful:', paymentResult);
        // Handle successful payment (e.g., show success message, redirect to confirmation page)
      } catch (err) {
        setError(err.message);
      } finally {
        setProcessing(false);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="email"
        value={customerEmail}
        onChange={(e) => setCustomerEmail(e.target.value)}
        placeholder="Enter your email"
        required
      />
      <CardElement />
      {error && <div className="error">{error}</div>}
      <button type="submit" disabled={processing}>
        {processing ? 'Processing...' : 'Pay'}
      </button>
    </form>
  );
}

function PaymentForm({ price, audioBlob, uploadedFile }) {
  return (
    <div className="payment-form">
      <h2>Payment</h2>
      <Elements stripe={stripePromise}>
        <CheckoutForm price={price} audioBlob={audioBlob} uploadedFile={uploadedFile} />
      </Elements>
    </div>
  );
}

export default PaymentForm;