import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

import AudioRecorder from './AudioRecorder';
import AudioUploader from './AudioUploader';
import PaymentForm from './PaymentForm';

function ProductSelection({ selectedProduct, setSelectedProduct, setMessage, prices}) {
    const [audioBlob, setAudioBlob] = useState(null);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [price, setPrice] = useState(null);
    const [currency, setCurrency] = useState('');
    const [priceId, setPriceId] = useState(null);


    useEffect(() => {
        setPrice(prices[selectedProduct.id].amount);
        setCurrency(prices[selectedProduct.id].currency);
        setPriceId(prices[selectedProduct.id].price_id);
        
    }, [selectedProduct]);

    const handleBack = () => {
        setSelectedProduct(null);
        setAudioBlob(null);
        setUploadedFile(null);
        setMessage(null)
        // Track the "Back" button click
        ReactGA.event({
            category: 'User',
            action: 'Clicked Back',
        });
    };

    const handleCheckout = async (e) => {
        e.preventDefault();
        if (!priceId) {
            console.error('Price ID is not set');
            return;
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/create-checkout-session?price_id=${priceId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Network response was not ok');
            }

            const data = await response.json();
            // Redirect to Stripe Checkout
            window.location.href = data.checkout_url;
        } catch (error) {
            console.error('Error creating checkout session:', error);
        }
    };

    return (
    <div className="product-selection">
        <button onClick={handleBack}>Back</button>

        {selectedProduct.images && (
            <img src={selectedProduct.images[0]} alt={selectedProduct.name} className="product-image" />
        )}

        <h1>{selectedProduct.name}</h1>

        {price !== null && (
            <div className="price-box">
                <p>{currency} {price.toFixed(2)}</p>
            </div>
        )}

        <p>{selectedProduct.description}</p>
        <p>Record or Upload an audio, reading the following test.</p>

        <div className="audio-input-container">
            <AudioRecorder onRecordingComplete={setAudioBlob} />
            {/* <AudioUploader onFileUpload={setUploadedFile} /> */}
        </div>
        
        <form action={`${process.env.REACT_APP_API_URL}/create-checkout-session?price_id=${priceId}`} method="POST">
            <button type="submit" onClick={handleCheckout} disabled={!priceId}>Checkout</button>
        </form>

    </div>
    );
}

export default ProductSelection;