import React, { useState, useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import { 
    ToggleButtonGroup, 
    Card, 
    Button,
    Flex,
    ToggleButton } from '@aws-amplify/ui-react';

import AudioUploader from './AudioUploader';
import AudioRecorder from './AudioRecorder'
import awsconfig from '../aws-exports';
import { generateClient } from 'aws-amplify/data';
import { uploadData } from 'aws-amplify/storage';
import { createVoiceSentimentAnalysis } from '../graphql/mutations';

Amplify.configure(awsconfig);

const client = generateClient();

export default function VoiceTest({setSentimentAnalysisResults}) {
    const [selectedInputMethod, setSelectedInputMethod] = useState("recorder");
    const [audioUrl, setAudioUrl] = useState(null);
    const [submitSuccess, setSubmitSuccess] = useState(false);

    const handleRecordingComplete = (file) => {
        setAudioUrl(file);
        console.log('Audio URL: ', audioUrl);
    };
    
      const handleAudioSubmit = async () => {
        console.log('Audio URL: ', audioUrl);
        const file = audioUrl;
    
        try {
          const result = await uploadData({
            path: ({identityId}) => {
              const now = new Date();
              const timestamp = now.toISOString().replace(/[:.]/g, '-');
              return `protected/${identityId}/test-${timestamp}.wav`;
            },
            data: file,
          }).result;
          console.log('Path from Response: ', result.path);
          setSubmitSuccess(true);
    
          const res = await client.graphql({
            query: createVoiceSentimentAnalysis,
            variables: {
              input: {
                description: 'My first todo!',
                audioUrl: result.path,
              }
            }
          });
    
          const query_result = res.data.createVoiceSentimentAnalysis;
          console.log("sentiment analysis results", query_result)
          setSentimentAnalysisResults(query_result);
    
        } catch (error) {
          console.log('Error : ', error);
        }
    };

    return (
        <Card 
            width="100%" 
        >
            <Flex justifyContent="center">
                <Flex direction="column" gap="2rem">
                    <ToggleButtonGroup 
                        value={selectedInputMethod}
                        isExclusive
                        onChange={(value) => setSelectedInputMethod(value)}
                        >
                        <ToggleButton value="recorder">Record</ToggleButton>
                        <ToggleButton value="uploader">Upload</ToggleButton>
                    </ToggleButtonGroup>
                    
                    {selectedInputMethod === "recorder" ? (
                        <AudioRecorder onRecordingComplete={handleRecordingComplete} />
                    ) : (
                        <AudioUploader />
                    )}

                    <Button onClick={handleAudioSubmit}>Submit</Button>
                </Flex>
            </Flex>
        </Card>
    );
}