import React from 'react'; // Import React
import { View, Flex, Card, Tabs, Button, Divider } from '@aws-amplify/ui-react'; // Import necessary components from AWS Amplify
import VoiceTest from './VoiceTest'; // Import VoiceTest component
import VoiceTestResult from './VoiceTestResult'; // Import VoiceTestResult component
import ProductPage from './ProductPage'; // Import ProductPage component

const Message = ({ message, className }) => (
    <div className={className}>
      <p>{message}</p>
    </div>
);

function SentimentReport({ 
    message, 
    messageClassname, 
    tab, 
    setTab, 
    setSentimentAnalysisResults, 
    sentimentAnalysisResults, 
    setMessage 
}) {
    return (
        <View 
            width="80%"
            // backgroundColor="var(--amplify-colors-white)"
        >
            <Flex 
                direction="column"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="nowrap"
                gap="1rem"
            >
                {message &&
                    <Card width="100%">
                    <Message message={message} className={messageClassname}/>
                    </Card>
                }
            
                <Tabs
                    value={tab}
                    onValueChange={(tab) => setTab(tab)}
                    items={[
                    {
                        label: 'Take Test',
                        value: '1',
                        content: (
                            <>
                                <VoiceTest setSentimentAnalysisResults={setSentimentAnalysisResults}/>
                                <VoiceTestResult sentimentAnalysisResults={sentimentAnalysisResults}/>
                            </>
                        ),
                    },
                    {
                        label: 'Past Reports',
                        value: '2',
                        content: (
                        <>
                            <p>Content of the second tab.</p>
                            <Button isFullWidth onClick={() => setTab('1')}>
                            Go to first tab
                            </Button>
                        </>
                        ),
                    },
                    {
                        label: 'Voice Tests',
                        value: '3',
                        content: (
                            <ProductPage setMessage={setMessage}/>
                        ),
                    },
                    ]}
                />

                <Divider
                    orientation="horizontal" 
                />
            </Flex>
        </View>
    )
}

export default SentimentReport;
