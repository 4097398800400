import { 
    Text, } from '@aws-amplify/ui-react';

export default function SentimentResult({result}) {
    return (
        <>
            <Text>{result.description}</Text>
            <Text>{result.id}</Text>
        </>
    );
}
