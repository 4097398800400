import { 
    Card, 
    Heading,
    Flex,
    Accordion, } from '@aws-amplify/ui-react';

import SentimentResult from './SentimentResult.jsx';

export default function VoiceTestResult({sentimentAnalysisResults}) {
 
    return (
        <Card width="100%">
            {sentimentAnalysisResults && (
                <Flex direction="column">
                <Flex justifyContent="center">
                    <Heading level={2}>Voice Results</Heading>
                </Flex>
                <Accordion allowMultiple
                    items={[
                    {
                        trigger: 'Sentiment',
                        value: 'accessible',
                        content: (
                        <SentimentResult result={sentimentAnalysisResults}/>
                        )
                    },
                    {
                        trigger: 'Demographics',
                        value: 'styling',
                        content: 'Of course! See the section on CSS Styling below.'
                    },
                    {
                        trigger: 'Physicality',
                        value: 'content',
                        content: 'Most definitely!'
                    }
                    ]}
                />
                </Flex>
            )}
        </Card>
    );
}