import React, { useEffect, useState } from 'react'; // Import React and necessary hooks
import { Authenticator, Heading, Text, Button, View } from '@aws-amplify/ui-react'; // Import necessary components
import { getCurrentUser } from 'aws-amplify/auth';
import ReactGA from 'react-ga4';

function ThanksSignUp({ authFormFields, authComponents }) { // Accept props
    const [username, setUserName] = useState(null); // State to hold the username
    const [email, setEmail] = useState(null); // State to hold the email
    const handleEmailClick = () => {
        window.location.href = 'mailto:youremail@example.com'; // Replace with your email address
    };

    useEffect(() => {
        ReactGA.send({ hitType: "event", eventCategory: "Signed Up", eventAction: "Reached Thanks Sign Up Page" });
        window.gtag_report_conversion();
    }, []);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                let { username, userId, signInDetails } = await getCurrentUser();
                setUserName(username);
                setEmail(signInDetails.loginId);
            } catch (error) {
                console.error("Error fetching user:", error); // Log the error
                setUserName(null);
            }
        };
        fetchUser();
    }, []);

    return (
        <Authenticator formFields={authFormFields} components={authComponents}>
            <View style={{ padding: '2rem' }}> {/* Added padding to the View */}
                <Heading level={1} color="#eeaf37" fontWeight="bold" style={{ marginBottom: '1rem' }}> {/* Added margin to Heading */}
                    Thanks for signing up!
                </Heading>
                <Text style={{ marginBottom: '1rem', fontSize: '1.3rem' }}> {/* Increased font size */}
                    We appreciate your interest in RateMyVoice. We'll be in touch soon once we have more.
                </Text>
                <Text style={{ marginBottom: '1rem', fontSize: '1.3rem' }}> {/* Increased font size */}
                    In the meantime, feel free to drop us a line about what got you interested and features you'd like to see.
                </Text>

                <Button size="large" backgroundColor="#eeaf37" color="#000000" onClick={handleEmailClick}>
                    Email Us
                </Button>
            </View>
        </Authenticator>
    )
}

export default ThanksSignUp;
