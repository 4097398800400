import React, { useState, useRef, useEffect } from 'react';
import { FaMicrophone, FaStop, FaPlay, FaRedo } from 'react-icons/fa';
import '../styles/audioRecorder.css';

import { 
  Card, 
  Button,
  Heading,
  Badge,
  Flex,} from '@aws-amplify/ui-react';

function AudioRecorder({ onRecordingComplete }) {
  const [isRecording, setIsRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const mediaRecorder = useRef(null);
  const audioRef = useRef(null);
  const timerRef = useRef(null);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder.current = new MediaRecorder(stream);
      
      mediaRecorder.current.ondataavailable = (event) => {
        const audioBlob = new Blob([event.data], { type: 'audio/wav' });
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioUrl(audioUrl);
        onRecordingComplete(event.data);
      };
      
      mediaRecorder.current.start();
      setIsRecording(true);
      setElapsedTime(0);
      startTimer();
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current && isRecording) {
      mediaRecorder.current.stop();
      setIsRecording(false);
      mediaRecorder.current.stream.getTracks().forEach(track => track.stop());
      stopTimer();
    }
  };

  const handleReplay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleReRecord = () => {
    setAudioUrl(null);
    setIsPlaying(false);
    setElapsedTime(0);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  };

  const startTimer = () => {
    timerRef.current = setInterval(() => {
      setElapsedTime((prevTime) => prevTime + 1);
    }, 1000);
  };

  const stopTimer = () => {
    clearInterval(timerRef.current);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    return () => {
      stopTimer();
    };
  }, []);

  return (
    <Card>
      <Flex direction="column">
        <Heading level={2}>Record Audio</Heading>
        <Flex justifyContent="center">
          <Badge
            size="large"
            variation="info">
            {formatTime(elapsedTime)}
          </Badge>
        </Flex>

        <Flex justifyContent="center">
          {!isRecording && !audioUrl && (
              <Button onClick={startRecording}>
                <FaMicrophone /> Start Recording
              </Button>
            )}
            {isRecording && (
              <Button onClick={stopRecording}>
                <FaStop /> Stop Recording
              </Button>
            )}
            {audioUrl && (
              <>
                <Button onClick={handleReplay}>
                  {isPlaying ? <FaStop /> : <FaPlay />} {isPlaying ? 'Stop' : 'Play'} Recording
                </Button>
                <Button onClick={handleReRecord}>
                  <FaRedo /> Re-record
                </Button>
              </>
            )}
        </Flex>

        <Flex justifyContent="center">
          {audioUrl && <audio ref={audioRef} src={audioUrl} />}
        </Flex>
      </Flex>

    </Card>
  );
}

export default AudioRecorder;