import React, { useState, useEffect } from 'react';

import Products from './Products';
import ProductSelection from './ProductSelection';

export default function ProductPage({setMessage}) {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [products, setProducts] = useState([]);
    const [prices, setPrices] = useState({});

    const handleProductSelect = ({ selectedProduct, prices }) => {
        setSelectedProduct(selectedProduct);
        setPrices(prices);
    };

    return (
        <>
            {!selectedProduct ? (
                <Products 
                    onSelect={handleProductSelect} 
                    products={products} 
                    setProducts={setProducts}
                    prices={prices}
                    setPrices={setPrices}
                />
            ) : (
                <ProductSelection 
                    selectedProduct={selectedProduct} 
                    setSelectedProduct={setSelectedProduct} 
                    setMessage={setMessage}
                    prices={prices}
                />
            )}
        </>
    );
}