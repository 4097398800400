/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createVoiceSentimentAnalysis = /* GraphQL */ `
  mutation CreateVoiceSentimentAnalysis(
    $input: CreateVoiceSentimentAnalysisInput!
    $condition: ModelVoiceSentimentAnalysisConditionInput
  ) {
    createVoiceSentimentAnalysis(input: $input, condition: $condition) {
      id
      description
      audioUrl
      emotions {
        id
        name
        description
        score
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateVoiceSentimentAnalysis = /* GraphQL */ `
  mutation UpdateVoiceSentimentAnalysis(
    $input: UpdateVoiceSentimentAnalysisInput!
    $condition: ModelVoiceSentimentAnalysisConditionInput
  ) {
    updateVoiceSentimentAnalysis(input: $input, condition: $condition) {
      id
      description
      audioUrl
      emotions {
        id
        name
        description
        score
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteVoiceSentimentAnalysis = /* GraphQL */ `
  mutation DeleteVoiceSentimentAnalysis(
    $input: DeleteVoiceSentimentAnalysisInput!
    $condition: ModelVoiceSentimentAnalysisConditionInput
  ) {
    deleteVoiceSentimentAnalysis(input: $input, condition: $condition) {
      id
      description
      audioUrl
      emotions {
        id
        name
        description
        score
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
