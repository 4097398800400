'use client';
import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import './styles/main.css';
import { Amplify } from 'aws-amplify';
import Header from "./components/Header.jsx"
import Footer from "./components/Footer.jsx"
import LandingPage from "./components/LandingPage.jsx"
import VoiceTest from './components/VoiceTest.jsx';
import VoiceTestResult from './components/VoiceTestResult.jsx';
import ProductPage from './components/ProductPage.jsx';
import SentimentReport from './components/SentimentReport.jsx';
import { API, graphqlOperation } from 'aws-amplify';
import { listVoiceSentimentAnalyses } from './graphql/queries';
import { authFormFields, authComponents } from './auth.js';



import ThanksSignUp from './components/ThanksSignUp.jsx';

import { 
  defaultDarkModeOverride,
  ThemeProvider, 
  Authenticator, 
  ToggleButtonGroup, 
  Card, 
  Tabs,
  Button,
  Heading,
  Flex,
  Accordion,
  View,
  Divider,
  ToggleButton } from '@aws-amplify/ui-react';
import awsconfig from './aws-exports';

import '@aws-amplify/ui-react/styles.css';
import '@aws-amplify/ui-react/styles/reset.layer.css' // global CSS reset
import '@aws-amplify/ui-react/styles/base.layer.css' // base styling needed for Amplify UI
import '@aws-amplify/ui-react/styles/button.layer.css' // component specific styles
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import Routes and Route

Amplify.configure(awsconfig);

// const result = await client.graphql({
//   query: listVoiceSentimentAnalyses,
// })

// console.log("result", result)

const Message = ({ message, className }) => (
  <div className={className}>
    <p>{message}</p>
  </div>
);

function App() {
  const [message, setMessage] = useState(null);
  const [messageClassname, setMessageClassname] = useState(null);
  
  // const [colorMode, setColorMode] = React.useState('system');

  const [sentimentAnalysisResults, setSentimentAnalysisResults] = useState(null);
  const [tab, setTab] = useState('1');

  useEffect(() => {
    ReactGA.initialize('G-75S8S82W5V');
  }, []);
  
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setMessage("Order placed! You will receive an email confirmation.");
      setMessageClassname("message-container-success");
      // setSelectedProduct(null);
    }

    if (query.get("canceled")) {
      setMessage("Order canceled -- continue to shop around and checkout when you're ready.");
      setMessageClassname("message-container-error");
    }
  }, []);

  useEffect(() => {
    // Send pageview on component mount
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const theme = {
    name: 'my-theme',
    overrides: [defaultDarkModeOverride],
  };

  return (
    <Router>
      <ThemeProvider theme={theme} colorMode="dark">
        <Flex 
          direction="column"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          wrap="nowrap"
          gap="1rem"
        >
          <Header/>

          <View width="80%">
            <Divider
              orientation="horizontal" 
            />
          </View>

          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/thanks-signup" element={<ThanksSignUp authFormFields={authFormFields} authComponents={authComponents} />} />
            <Route path="/sentiment-report" element={<SentimentReport 
              message={message} 
              messageClassname={messageClassname}
              tab={tab}
              setTab={setTab}
              setSentimentAnalysisResults={setSentimentAnalysisResults}
              sentimentAnalysisResults={sentimentAnalysisResults}
              setMessage={setMessage}
              setMessageClassname={setMessageClassname}
            />} />
          </Routes>

          <Footer/>
        </Flex>
      </ThemeProvider>
    </Router>
  );
}

export default App;
