import React, { useState, useEffect } from 'react';

import { 
    Card, 
    Button,
    Heading,
    Avatar,
    Flex,
    View,
    Image,} from '@aws-amplify/ui-react';

import { signOut, getCurrentUser } from 'aws-amplify/auth';

export default function Footer() {

    return (
        <>
            <View
                as="div"
                // ariaLabel="ald;kfjsdlfk"
                // backgroundColor="var(--amplify-colors-blue)"
                borderRadius="6px"
                border="1px solid var(--amplify-colors-white)"
                boxShadow="3px 3px 3px 4px var(--amplify-colors-neutral-60)"
                // color="var(--amplify-colors-blue-60)"
                // height="4rem"
                display="inline-block"
                maxWidth="100%"
                padding="1rem"
                width="80%"
            >
                <Flex 
                direction="row"
                alignItems="flex-end"
                justifyContent="flex-end" // Added to align items to the right
                >
                <Card 
                    width="50%" 
                    // border="1px solid var(--amplify-colors-white)"
                    >
                    RateMyVoice. All rights reserved.
                </Card>

                <Card 
                    width="50%"
                    // border="1px solid var(--amplify-colors-white)"
                    >
                    <p style={{ fontSize: '0.25rem', color: 'black' }}>
                        Icons from Freepik
                    </p>
                </Card>
                </Flex>
            </View>
        </>
    );
}
